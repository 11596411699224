<template>
  <v-dialog v-model="isOpen" width="800">
    <v-card>
      <v-card-title>
        {{ vorstoss.title }}
      </v-card-title>
      <v-card-text>
        <dl>
          <dt>{{ $t('Status') }}</dt>
          <dd>{{ vorstoss.status_display }}</dd>

          <dt>{{ $t('Business_type') }}</dt>
          <dd>{{ businessTypeLabel }}</dd>

          <dt>{{ $t('Wording') }}</dt>
          <dd>{{ vorstoss.description }}</dd>

          <dt>{{ $t('Business_case_reason') }}</dt>
          <dd>{{ vorstoss.reason }}</dd>

          <dt>{{ $t('Business_case_proposal_creator') }}</dt>
          <dd>{{ firstSignatory }}</dd>

          <dt>{{ $t('Business_case_second_signature') }}</dt>
          <dd>{{ secondSignatory }}</dd>

          <dt>{{ $t('Business_case_third_signature') }}</dt>
          <dd>{{ thirdSignatory }}</dd>

          <dt>{{ $t('Business_case_other_signature') }}</dt>
          <dd v-if="hasOtherSignatories">{{ otherSignatories.join(', ') }}</dd>
          <dd v-else>-</dd>

          <dt>{{ $t('Business_case_rights_holder') }}</dt>
          <dd>{{ vorstoss.rights_holder_display }}</dd>

          <dt>{{ $t('mark_urgent') }}</dt>
          <dd>
            <v-icon small :color="enabledColor(vorstoss.is_urgent)">{{ enabledIcon(vorstoss.is_urgent) }}</v-icon>
          </dd>

          <dt>{{ $t('Attachments') }}</dt>
          <dd>
            <ul class="pl-4">
              <li v-for="attachment in vorstoss.attachments" :key="attachment.name">
                <a :href="attachment.url">{{ attachment.name }}</a>
              </li>
            </ul>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="isOpen = false">{{ $t('Cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import find from 'lodash/find'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'VorstossDetailDialog',
  data() {
    return {
      isOpen: false,
      vorstoss: {},
    }
  },
  computed: {
    firstSignatory() {
      return this.signatoryLabel(find(this.vorstoss.signatories, { role: 'FIRST_SIGNATORY' }))
    },
    secondSignatory() {
      return this.signatoryLabel(find(this.vorstoss.signatories, { role: 'SECOND_SIGNATORY' }))
    },
    thirdSignatory() {
      return this.signatoryLabel(find(this.vorstoss.signatories, { role: 'THIRD_SIGNATORY' }))
    },
    otherSignatories() {
      return filter(this.vorstoss.signatories, { role: 'OTHER_SIGNATORY' }).map(this.signatoryLabel)
    },
    businessTypeLabel() {
      return get(this.vorstoss, 'business_type.text')
    },
    hasOtherSignatories() {
      return !isEmpty(this.otherSignatories)
    },
  },
  methods: {
    open(vorstoss) {
      this.vorstoss = vorstoss
      this.isOpen = true
    },
    signatoryLabel(signatory) {
      if (isUndefined(signatory)) return ''

      if (signatory.type === 'user') {
        return signatory.full_name
      }

      if (signatory.type === 'committee' || signatory.type === 'committeegroup') {
        return signatory.title || ''
      }

      return ''
    },

    enabledIcon(value) {
      return value ? 'mdi-check-circle' : 'mdi-close-circle'
    },
    enabledColor(value) {
      return value ? 'success' : 'error'
    },
  },
}
</script>
